import axios from "axios";
import React, { useState } from "react";
import { Button, Skeleton, Stack } from "@mui/material";

import {
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TablePagination,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { apiUrl } from "../constants";
import Auth from "../Authentication";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function Circular() {
  const { token } = Auth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Responsive breakpoint

  let config = {
    headers: {
      Authorization: token,
    },
  };

  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [data, setData] = useState([]);

  const skeletonArray = Array.from({ length: 5 }, (_, index) => (
    <div key={index}>
      <Skeleton variant="wave" width={"100%"} height={40} />
      <br />
    </div>
  ));

  const handleViewPDF = (pdfLink) => {
    window.open(pdfLink, "_blank");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async (apiEndpoint, label) => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/${apiEndpoint}`, config);
      const responseData = res.data?.["body-json"]?.["body"];
      if (responseData && responseData.length > 0) {
        setData(responseData);
        setText(label);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        spacing={isSmallScreen ? 2 : 5}
        className="justify-center"
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => fetchData("jmoa_resolution_all_data", "Acts & Rules")}
        >
          Acts & Rules
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => fetchData("jmoa_circular_all_data", "Circular")}
        >
          Circular
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => fetchData("get_all_documents", "JSNGEF Documents")}
        >
          JSNGEF Documents
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => fetchData("jmoa_notice_all_data", "Notice")}
        >
          Publications
        </Button>
      </Stack>
      <br />
      <div>
        <Typography
          variant="h5"
          className="text-xl font-bold mb-4 text-center"
          style={{ color: "blue" }}
        >
          {text}
        </Typography>
        {loading ? (
          skeletonArray
        ) : (
          <>
            {text && data.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Issue No.
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Description
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>PDF</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.date}</TableCell>
                          <TableCell>{item.issueNo}</TableCell>
                          <TableCell>{item.title}</TableCell>
                          <TableCell>
                            <VisibilityIcon
                              style={{
                                color: "green",
                                cursor: "pointer",
                              }}
                              onClick={() => handleViewPDF(item.link)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            ) : (
              text && (
                <Typography
                  variant="body1"
                  className="text-center"
                  style={{ color: "red" }}
                >
                  No data available for {text}
                </Typography>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
}
